import { Logger } from '@tellsla/common';
import { config } from '@tellsla/config';
import axios from 'axios';
import { baseApiUrl, IFrontHostConfig } from './urls';

const logger = Logger('restApi');

axios.defaults.timeoutErrorMessage = 'server timeout';

/**
 * Оберточная функция, создающая стандартное axiox-обращение на серевер
 * @returns
 */
export const restAPI = () =>
    axios.create({
        baseURL: `${baseApiUrl(config.backendHost as IFrontHostConfig)}${config.backendHost.restApi.path}`,
        timeout: config.backendHost.timeout,
        responseType: 'json',
        headers: config.backendHost.restApi.headers,
    });

export const { CancelToken } = axios;

/**
 * Выставляем / запоминаем полученный на сервере токен для всех последующих запросов.
 * @param token само значение токена в виде base64 строки. При передаче значения false или null токен будет удален.
 */
export function setHttpAuthToken(token: string) {
    if (token) {
        logger.Debug('HTTP Auth token set');
        // Apply authorization token to every request if logged in
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        logger.Debug('HTTP Auth token deleted');
        // Delete auth header
        delete axios.defaults.headers.common.Authorization;
    }
}


export * from './urls';
