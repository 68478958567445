import { createTheme, Palette, PaletteColor, ThemeOptions, TypeBackground } from '@mui/material/styles';
import { merge } from 'lodash';
import { getColors } from 'theme-colors';

// const primaryColor = { main: '#59503b' }; // темно-темно желтый
// const secondaryColor = { main: '#304068' }; //

// const primaryColor = { main: '#374857' }; // джинс
// const primaryColor = { main: '#3c456d' }; // джинс2
const primaryColor = { main: '#37637f' }; // джинс3
// const primaryColor = { main: '#e0eff9' }; // светло-голубой
// const secondaryColor = { main: '#7C6646' };
// const secondaryColor = { main: '#806C4F' };
// const secondaryColor = { main: '#80653F' }; // нубук
// const secondaryColor = { main: '#A0712C' }; // нубук 2
const secondaryColor = { main: '#CBA04E' }; // под золото к светлому основному

interface IColorWithVariants extends PaletteColor {
    main: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
}

export interface IBackgroundColors extends TypeBackground {
    hilighted: string;
    selectedSlide: string;
    selectingSlide: string;
    semiTransparent: string;
    quarterTransparent: string;
    iconNumberTag: string;
    landing: string;
    landingCard: string;
    textCard: string;
    listPaper: string;
    editPaper: string;
}

interface IGroupColors {
    default: string;
}

export interface IPalette extends Palette {
    primary: IColorWithVariants;
    secondary: IColorWithVariants;
    background: IBackgroundColors;
    group: IGroupColors;
    primaryLight: IColorWithVariants;
    primaryDark: IColorWithVariants;
}

const basicPalette = {
    // mode: 'light',
    primary: {
        main: primaryColor.main,
        ...getColors(primaryColor.main),
    } as IColorWithVariants,
    secondary: {
        main: secondaryColor.main,
        ...getColors(secondaryColor.main),
    } as IColorWithVariants,
    warning: {
        main: '#f5ac41',
    },
    info: {
        main: '#558cb7',
    },
    background: {
        main: '#bec2ca',
        default: '#bec2ca',
        hilighted: '#f0f0f0',
        selectedSlide: '#a7a7a7',
        selectingSlide: '#fefece',
        semiTransparent: '#d3d3d36d',
        quarterTransparent: '#80808075',
        iconNumberTag: '#ffa83b',
        landing: '#afb2ba',
        // landing: '#e2e4e7',
        landingCard: '#f0f3f5',
        textCard: '#f4f7ff',
        listPaper: '#f3f3f4',
        editPaper: '#fff',
    },
    secondaryTopBar: { main: primaryColor.main },
    group: {
        default: '#b2b2b2',
    },
    // action: {
    //     active: lightBlue[200],
    //     activeOpacity: 1,
    //     hover: lightBlue[100],
    //     hoverOpacity: 0.7,
    //     focus: lightBlue[600],
    //     focusOpacity: 1,
    //     selected: lightBlue[300],
    //     selectedOpacity: 1,
    // },
};

const basicTheme = createTheme({
    palette: {
        ...basicPalette,
        primaryLight: {
            main: basicPalette.primary[200],
        },
        primaryDark: {
            main: basicPalette.primary[800],
        },
    } as any,
});

// console.log('theme palette: ', { palette: basicTheme.palette, basicPalette });
// export const topBarHeight = 35;
// TODO: Перенести эти константы в theme
export const landingHeaderHeight = 46;
export const topBarHeight = 48;
export const leftDrawerWidth = 220;
export const rightDrawerWidth = 300;
export const roomControlPanelHeight = 120;
export const minimalSpeakerWidth = 120;

export const chatTheme = {
    message: {
        header: {
            fontSize: '80%',
            fontWeight: '600',
        },
        text: {
            fontSize: '90% !important',
            fontWeight: '400',
        },
    },
};

const quizTheme = {
    typography: {
        quizPlayerQuestionText: {
            fontSize: '1.3rem',
        },
        quizPlayerQuestionAnswer: {
            fontSize: '1.2rem',
        },
    },
};

export const theme = createTheme(
    merge(
        {},
        {
            palette: basicTheme.palette,

            breakpoints: {
                values: {
                    xs: 0,
                    col: 300,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                },
            },
            typography: {
                fontFamily: ['"Roboto" '].concat(basicTheme.typography.fontFamily ?? ''),
                fontSize: 12,
                // ...quizTheme.typography,
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        p: {
                            ...basicTheme.typography.body2,
                            marginBlockStart: 0,
                        },
                        root: {
                            width: '100%',
                            colorScheme: 'light',
                        },
                    },
                },
                MuiScopedCssBaseline: {
                    styleOverrides: {
                        root: {
                            width: '100%',
                            colorScheme: 'light',
                        },
                    },
                },
                MuiAppBar: {
                    defaultProps: {
                        color: 'secondaryTopBar',
                    },
                },

                MuiCircularProgress: {
                    defaultProps: {
                        color: 'primaryLight',
                    },
                },
                MuiButton: {
                    defaultProps: {
                        size: 'small',
                        color: 'primary',
                        variant: 'outlined',
                    },
                    styleOverrides: {
                        root: {
                            fontSize: '0.9rem',
                        },
                    },
                },
                MuiButtonGroup: {
                    defaultProps: {
                        variant: 'text',
                        color: 'primary',
                        size: 'small',
                    },
                },
                MuiIconButton: {
                    defaultProps: {
                        color: 'primary',
                    },
                },
                MuiInputLabel: {
                    defaultProps: {
                        // color: 'secondary', // basicTheme.palette.primary.dark,
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            position: 'absolute',
                            top: '100%',
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        variant: 'standard',
                        margin: 'normal',
                    },
                },
                MuiInput: {
                    defaultProps: {
                        variant: 'standard',
                    },
                },
                MuiDivider: {
                    styleOverrides: {
                        root: {
                            margin: '0 8px', // margin-top и botton не работают
                        },
                    },
                },
                MuiTabs: {
                    styleOverrides: {
                        root: {
                            height: '28px',
                            minHeight: '28px',
                            backgroundColor: basicTheme.palette.primary[100],
                        },
                        flexContainer: {
                            height: '100%',
                            '& .form-tab': {
                                paddingTop: '0',
                                paddingBottom: '0',
                            },
                        },
                    },
                },
                MuiTab: {
                    styleOverrides: {
                        root: {
                            minHeight: '14px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            fontWeight: 'bold',
                        },
                    },
                },
                MuiToolbar: {
                    defaultProps: {
                        color: 'background.secondaryTopBar',
                    },
                    styleOverrides: {
                        alignItems: 'baseline',
                    },
                },
                RaAppBar: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#e0eff9',
                        },
                    },
                },
                RaCreate: {
                    styleOverrides: {
                        root: {
                            '& .RaCreate-main': {
                                marginTop: 0,
                            },
                        },
                    },
                },
                RaEdit: {
                    styleOverrides: {
                        root: {
                            maxHeight: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            '& .RaEdit-main': {
                                marginTop: 0,
                                height: '100%',
                                overflow: 'hidden',
                            },
                            '& .RaEdit-card': {
                                height: '100%',
                                maxHeight: '100%',
                            },
                        },
                    },
                },
                RaUserMenu: {
                    styleOverrides: {
                        root: {
                            '& .RaUserMenu-avatar': {
                                color: basicTheme.palette.getContrastText(
                                    basicTheme.palette.background.default
                                ),
                            },
                        },
                    },
                },
                RaReferenceField: {
                    styleOverrides: {
                        root: {
                            '& .RaReferenceField-link': {
                                textDecoration: 'underline',
                            },
                        },
                    },
                },
                RaSelectInput: {
                    styleOverrides: {
                        root: {
                            '& .RaSelectInput-input': {
                                variant: 'outlined',
                            },
                        },
                    },
                },
            },
            overrides: {
                MuiToolbar: {
                    dense: {
                        gutters: {
                            paddingLeft: '12px',
                            paddingRight: '12px',
                        },
                    },
                    gutters: {
                        paddingLeft: '12px',
                        paddingRight: '12px',
                    },
                },
                MuiListItem: {
                    root: {
                        '&$selected': {
                            backgroundColor: basicTheme.palette.primary.dark,
                            '&:hover': {
                                backgroundColor: basicTheme.palette.primary.main,
                            },
                        },
                        '&:hover': {
                            backgroundColor: basicTheme.palette.primary.main,
                        },
                    },
                },
                switchBase: {
                    color: (basicTheme.palette.secondary as unknown as IColorWithVariants)[300],
                    '&$checked': {
                        color: (basicTheme.palette.secondary as unknown as IColorWithVariants)[500],
                    },
                    '&$checked + $track': {
                        backgroundColor: (basicTheme.palette.secondary as unknown as IColorWithVariants)[500],
                    },
                },
            },
            props: {
                MuiTextField: {
                    // variant: 'filled',
                    variant: 'standard',
                    margin: 'normal',
                },
                MuiFormControl: {
                    margin: 'normal',
                },
                MuiInput: {
                    variant: 'standard',
                },
                MuiInputLabel: {
                    shrink: true,
                },
                MuiAccordion: {
                    disableGutters: true,
                },
                MuiButton: {
                    variant: 'outlined',
                    color: 'secondary',
                    size: 'small',
                },
                RaButton: {
                    variant: 'outlined',
                },
            },
        },
        quizTheme,
        {
            // React Admin sets
            sidebar: { width: 210, closedWidth: 50 },
        }
    ) as unknown as ThemeOptions
);

export const NoColorCssPattern = {
    backgroundImage:
        'repeating-linear-gradient(45deg, #dedede 25%, transparent 25%, transparent 75%, #dedede 75%, #dedede), repeating-linear-gradient(45deg, #dedede 25%, #ffffff 25%, #ffffff 75%, #dedede 75%, #dedede)',
    backgroundPosition: '0 0, 9px 9px',
    backgroundSize: '18px 18px',
};

export const DragHereCssPattern = {
    background: 'radial-gradient( ellipse farthest-corner at 10px 10px , #dadada, #dadada 50%, #ffffff 50%)',
    backgroundSize: '10px 10px',
};

export const DefaultTableColors = [
    '#FF6900',
    '#FCB900',
    '#00D084',
    '#9900EF',
    '#0693E3',
    '#EB144C',
    '#F78DA7',
    '#8ED1FC',
    '#D2DC7A',
    basicPalette.primary.main,
    // '#ABB8C3',
];
