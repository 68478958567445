import EventEmitter from 'eventemitter3';
import { Logger } from '@tellsla/common';

const logger = Logger('NotifycationEmitter');

class NotifyActionsEmitter extends EventEmitter {
    emit<T extends EventEmitter.EventNames<string | symbol>>(type: T, ...args: any[]): boolean {
        logger.Debug(`${String(type)} emitted`, { ...args });
        return super.emit(type, ...args);
    }
}

const NotifycationEmitter = new NotifyActionsEmitter();

export default NotifycationEmitter;
