import { Box, styled } from '@mui/material';
import { config } from '@tellsla/config';

export const PlaybookPagePreviewContainer = styled(Box)(({ theme }) => ({
    width: `${config.defaults.mediaGridElement.maxWidth + 6}px`,
    height: `${config.defaults.mediaGridElement.maxHeight + 6}px`,
    padding: '6px',
    marginBottom: theme.spacing(3.5),
    border: 0,
    borderRadius: '4px',
}));
