import { Logger } from "../..";
import { eventRunRoles, canChangeLayout, isModeratorRole, isOrgRole, peerRoleCan, peerRoleIs } from "./eventRunRoles";

const commandsConfig = new Map([
    ['authorize', { mustHaveAuth: false, can: () => true }],
    ['joinEvent', { mustHaveAuth: true, can: () => true }],
    ['exitRoom', { mustHaveAuth: true, can: () => true }],
    ['disconnect', { mustHaveAuth: false, can: () => true }],
    ['logout', { mustHaveAuth: true, can: () => true }],
    ['raisedHand', { mustHaveAuth: true, can: () => true }],
    ['expressEmoji', { mustHaveAuth: true, can: () => true }],
    ['micIsOn', { mustHaveAuth: true, can: () => true }],

    ['updateToken', { mustHaveAuth: false, can: () => true }],
    ['changeUser', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isModer') }],

    ['eventCommand', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    ['changeTimeline', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],

    ['changeL2', { mustHaveAuth: true, can: canChangeLayout }],
    ['changeExposition', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    ['changeRoom', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    ['changeTable', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isModer') }],

    ['relocateUsersToRoom', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],

    ['isolateTable', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    ['uniteTable', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    ['isolateAllTables', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    ['uniteAllTables', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],

    ['playbookCommand', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    ['getPlaybook', { mustHaveAuth: true, can: () => true }],

    ['timerCommand', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],

    ['mediaCommand', { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    ['playbookTelemetry', { mustHaveAuth: true, can: () => true }],


    // Quiz answers
    ['quizRunCommand', { mustHaveAuth: true, can: (role: string): boolean => isOrgRole(role) }],
    ['quizRun', { mustHaveAuth: true, can: (): boolean => true }],

    // Quiz telemetry
    ['quizActivity', { mustHaveAuth: true, can: () => true }],

    ['startListenToTable', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'table', 'listen') }],
    ['stopListenToTable', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'table', 'listen') }],

    ['getProducers', { mustHaveAuth: true, can: () => true }],

    ['getRouterRtpCapabilities', { mustHaveAuth: true, can: () => true }],
    ['createWebRtcTransport', { mustHaveAuth: true, can: () => true }],
    ['connectTransport', { mustHaveAuth: true, can: () => true }],
    ['transport-produce', { mustHaveAuth: true, can: () => true }],

    ['consume', { mustHaveAuth: true, can: () => true }],
    ['resumeConsumer', { mustHaveAuth: true, can: () => true }],

    ['producerClosed', { mustHaveAuth: true, can: () => true }],

    ['userlist', { mustHaveAuth: true, can: () => false }],
    ['getUserList', { mustHaveAuth: true, can: () => true }],

    ['userlistCommand', { mustHaveAuth: true, can: isModeratorRole }],

    // Chat
    ['chatmessage', { mustHaveAuth: true, can: () => true }],

    // User Telemetry
    ['changeAppFocus', { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'userTelemetry', 'change') }],
    ['getMediaStats', { mustHaveAuth: true, can: () => true }],

    ['jobCreateUpload', { mustHaveAuth: true, can: () => true }],
    ['jobCancelUpload', { mustHaveAuth: true, can: () => true }],
]);

const logger = Logger('isCommandAllowed');

export const isCommandAllowed = (command: string, role: string, haveAuth: boolean): { result: boolean; message: string; } => {
    const commandConfig = commandsConfig.get(command);

    if (!commandConfig) {
        logger.Debug(`Unknown command [${command}]`);
        return { result: false, message: `Unknown command [${command}]` };
    }

    if (commandConfig.mustHaveAuth && !(haveAuth === true)) {
        logger.Debug(`Must be authorized for [${command}]`);
        return { result: false, message: `Must be authorized for [${command}]` };
    }

    if (!commandConfig.can(role)) {
        logger.Debug(`${role} don't have enough priviliges for [${command}]`);
        logger.Debug({ roleCfg: eventRunRoles.get(role.toUpperCase())})
        return { result: false, message: `No enough priviliges for [${command}]` };
    }

    return { result: true, message: '' };
};

export default {
    isCommandAllowed,
};
