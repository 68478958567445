import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Paper, styled, useTheme } from '@mui/material';
import { isTrue } from '@tellsla/common';
import { IMediafile, IPlaybookPage, IPlaybookPageMediaTypes, IQuizRun } from '@tellsla/serverTypes';
import { isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Mediafile } from '../Mediafile';
import { NoDataPlaceholder, SelfScroll } from '../common';
import { PlaybookPageQuizPreview } from './PlaybookPageQuizPreview';
import { PlaybookPageTitle } from './PlaybookPageTitle';
import { PlaybookPagePreviewContainer } from './PlaybookPagePreviewContainer';

export const StyledPaper = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    position: 'relative',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
});

interface IProps {
    pageIndex: number;
    page: IPlaybookPage;
    isSelected?: boolean;
}

export const PlaybookPagePreview: React.FC<IProps> = ({ pageIndex, page, isSelected = false }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const isHidden = isTrue(page?.options?.isHidden);

    const PreviewElement = () => {
        switch (page.mediaType) {
            case IPlaybookPageMediaTypes.MEDIAFILE:
                return (
                    <Mediafile
                        mediaFileId={String((page.media as IMediafile)._id ?? '')}
                        viewOptions={page.options}
                        forcePreview={true}
                        mediaFile={page.media as IMediafile}
                        updateMediaData={() => null}
                    />
                );

            case IPlaybookPageMediaTypes.QUIZRUN:
                return (
                    <PlaybookPageQuizPreview quizTitle={(page.media as IQuizRun)?.quiz?.title ?? t('Quiz')} />
                );

            default:
                return <NoDataPlaceholder>{t('Data could not be interpreted.')}</NoDataPlaceholder>;
        }
    };

    if (isNil(page)) return <NoDataPlaceholder />;

    return (
        <PlaybookPagePreviewContainer>
            <Paper
                elevation={8}
                sx={{ height: '100%', width: '100%', position: 'relative', boxSizing: 'unset' }}>
                <SelfScroll isSelected={isSelected}>
                    <StyledPaper>
                        {isNil(page) ? <NoDataPlaceholder /> : <PreviewElement />}
                        {isHidden ? (
                            <>
                                <Box
                                    sx={{
                                        backgroundColor: 'black',
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        opacity: isTrue(isHidden) ? 0.6 : 0,
                                    }}
                                />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: theme.spacing(1),
                                        right: theme.spacing(1),
                                        display: isTrue(isHidden) ? 'inherit' : 'none',
                                    }}>
                                    <VisibilityOffIcon sx={{ color: 'white' }} />
                                </Box>
                            </>
                        ) : null}
                    </StyledPaper>
                    <PlaybookPageTitle pageIndex={pageIndex} title={page.title} />
                </SelfScroll>
            </Paper>
        </PlaybookPagePreviewContainer>
    );
};
