/* eslint-disable no-console */
import debugEngine, { Debugger } from 'debug';

// debugEngine.enable('@t:*');

export type TLogger = {
    Log: Debugger,
    Warn: Debugger,
    Debug: Debugger,
    Err: Debugger,
};

/**
 * @param name имя пакета, под которым будут выводиться сообщения в лог
 * @returns функции вывода с соответствующим уровнем логирования
 */
export function Logger(moduleName: string): TLogger {

    const name = `@t:${moduleName}`;

    const Log = debugEngine(name);
    Log.log = console.log.bind(console);
    const Warn = debugEngine(name);
    Warn.log = console.warn.bind(console);
    const Debug = debugEngine(name);
    Debug.log = console.debug.bind(console);
    const Err = debugEngine(name);
    Err.log = console.error.bind(console);

    return ({
        Log,
        Warn,
        Debug,
        Err,
    });
}

export default Logger;